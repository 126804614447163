<template>
  <v-dialog
    v-model="dlg.visible"
    :width="dlg.width"
    hide-overlay
  >
    <v-card
      dark
      elevation="18"
      :height="dlg.height"
      class="dlg-alert-bg rounded-lg"
    >
      <div class="co-flex-col co-justify-center co-items-center co-w-full co-h-full">
        <v-icon
          v-if="types[type]"
          size="96"
          color="white"
        >
          {{ types[type] }}
        </v-icon>
        <span class="co-text-md co-text-white mt-4">{{ message }}</span>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    data () {
      return {
        dlg: {
          name: 'dlgAlert',
          visible: false,
          width: 260,
          height: 260
        },
        types: {
          success: 'mdi-check-circle-outline',
          warning: 'mdi-alert-circle-outline',
          info: 'mdi-alert-circle-outline',
          error: 'mdi-close-circle-outline'
        },
        type: '',
        message: ''
      }
    },
    methods: {
      show ({
        type,
        message
      }) {
        this.type = type
        this.message = message

        this.dlg.visible = true
        setTimeout(() => {
          this.dlg.visible = false
        }, 4000)
      }
    }
  }
</script>

<style scoped>
  .dlg-alert-bg {
    background-color: rgba(0, 0, 0, 0.6);
  }
</style>
